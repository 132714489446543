import React from 'react';
import Loadable from 'react-loadable';
import {
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  FormSizePartial,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const FormModifier = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.FormModifier;
    return <Component {...props} />;
  },
});

const Input = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Input;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/form-modifier.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Form Modifier"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-form-modifier--docs"
      />
      <CodeSnippet
        scope={{ React: React, FormModifier: FormModifier, Input: Input }}
        code={snippet}
        platform="react"
        gitHubLink="forms/form-modifier"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>Any form components you want to modify.</Text>
          </PropListItem>

          <ClassnamePartial componentName="form modifier" />

          <PropListItem name="density" types={['string']}>
            <Text>
              Determines the density of the form elements (how close they are in
              relation to each other).
            </Text>
            <List type="unordered">
              <li>
                <code>compact</code>
              </li>
              <li>
                <code>standard</code> (default)
              </li>
            </List>
          </PropListItem>

          <EnvironmentPartial />

          <FormSizePartial componentName="form modifier" />

          <PropListItem name="templateColumns" types={['string']}>
            <Text>
              Determines how the form elements are layed out in a grid by using
              any{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns">
                grid-template-columns
              </Link>{' '}
              values.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="form modifier" />
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
